import React from "react";

export const removeElementByName = (targetId: number, setObject: React.Dispatch<React.SetStateAction<any[]>>) => {
    setObject((prevObject) => {
        const newObject = prevObject
            .map((item) => removeNestedElementById(item, targetId))
            .filter((item) => item !== null);
        return newObject;
    });
};

export const removeNestedElementById = (obj: any, targetId: number): any => {
    if (Array.isArray(obj)) {
        return obj
            .map((item) => removeNestedElementById(item, targetId))
            .filter((item) => item !== null);
    }

    if (obj && typeof obj === 'object') {
        if (obj.id && obj.id === targetId) {
            return null;
        }

        const newObj: any = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const result = removeNestedElementById(obj[key], targetId);
                if (result !== null) {
                    newObj[key] = result;
                }
            }
        }
        return Object.keys(newObj).length === 0 ? {} : newObj;
    }

    return obj;
};

export const updateObjectById = (targetId: number, newData: any, setObject: React.Dispatch<React.SetStateAction<any[]>>) => {
    setObject((prevObject) =>
        prevObject.map((item) => {
            if (item.id && item.id === targetId) {
                console.log("Updated:", item.id);
                return { ...item, ...newData };
            }

            const updateNested = (obj: any): any => {
                if (obj.id && obj.id === targetId) {
                    return { ...obj, ...newData };
                }

                if (typeof obj === 'object' && !Array.isArray(obj)) {
                    const updatedObj: any = {};

                    for (const key in obj) {
                        if (obj[key] && typeof obj[key] === 'object') {
                            if (obj[key].id && obj[key].id === targetId && newData.marker !== obj[key].marker) {
                                updatedObj[newData.marker] = updateNested(obj[key]);
                            } else {
                                updatedObj[key] = updateNested(obj[key]);
                            }
                        } else {
                            updatedObj[key] = obj[key];
                        }
                    }

                    return updatedObj;
                }

                if (Array.isArray(obj)) {
                    obj = obj.map((nestedItem) => updateNested(nestedItem));
                }

                return obj;
            };

            return updateNested(item);
        })
    );
};

export const addObjectToValues = (obj: any, targetId: number, newObject: any): any | null => {
    const objectWithId = {
        id: Math.floor(Math.random() * 1000000000),
        ...newObject
    };

    if (obj.id && obj.id === targetId) {
        if (Array.isArray(obj.values)) {
            console.log("trueeeee");
            obj.values.push(objectWithId);
        } else if (obj.values && typeof obj.values === "object") {
            obj.values = {
                ...obj.values,
                [objectWithId.marker]: objectWithId
            };
        } else {
            obj.values = [objectWithId];
        }
        return obj;
    }

    if (Array.isArray(obj)) {
        for (let item of obj) {
            const result = addObjectToValues(item, targetId, objectWithId);
            if (result) {
                return result;
            }
        }
    }

    if (typeof obj === "object") {
        for (let key in obj) {
            const result = addObjectToValues(obj[key], targetId, objectWithId);
            if (result) {
                return result;
            }
        }
    }

    return null;
};


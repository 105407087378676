import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const mediaApi = createApi({
    reducerPath: 'mediaApi',
    tagTypes: ['Media', 'Files'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getMedia: builder.query({
        query: () => `/api/media/get`,
        providesTags: (result) =>
        result.data
          ? [
              ...result.data.map(({ id }: any) => ({ type: 'Media', id })),
              { type: 'Media', id: 'LIST' },
            ]
          : [{ type: 'Media', id: 'LIST' }],
      }),

      addMedia: builder.mutation({
        query: (data) => ({
          url: '/api/media/set',
          method: 'POST',
          body: data,
        }),
        invalidatesTags: [{ type: 'Media', id: 'LIST' }],
      }),

      updateMedia: builder.mutation({
        query: (data) => ({
          url: '/api/media/update',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Media', id: 'LIST' }],
      }),

      deleteMedia: builder.mutation({
        query: (data) => ({
          url: '/api/media/delete',
          method: 'DELETE',
          body: data,
        }),
        invalidatesTags: [{ type: 'Media', id: 'LIST' }],
      }),

      filesUpload: builder.mutation({
         query: (body) => ({
            url: `/api/files/upload`,
            method: "POST",
            body
        }),
        invalidatesTags: ["Files"]
      }),
        getFiles: builder.query({
            query: (params) => ({
                url: `/api/files/get`,
                method: "GET",
                params
            }),
            providesTags: ["Files"]
        }),
        deleteFile: builder.mutation({
            query: (body) => ({
                url: `/api/files/delete`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Files"]
        }),
    })  
})

export const {
    useGetMediaQuery,
    useAddMediaMutation,
    useUpdateMediaMutation,
    useDeleteMediaMutation,
    useFilesUploadMutation,
    useGetFilesQuery,
    useDeleteFileMutation
} = mediaApi;
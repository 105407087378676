import s from "./AddValues.module.scss";
import React from "react";
import {FormikErrors, FormikTouched, FormikValues} from "formik";
import {useTranslation} from "react-i18next";
import {DropDown} from "components/DropDown/DropDown";
import {useSelector} from "react-redux";
import {Input} from "components/Input/Input";

type AddValuesProps = {
    type: string;
    value: any;
    valuesForm: FormikValues;
    errors:  FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
}

export const AddValues = ({ type, value, valuesForm, errors, touched, setFieldValue }: AddValuesProps) => {
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);

    return (
        <div className={s.item_type}>
            <DropDown
                langs={langs}
                errors={errors[`${type}${value?.id}`]}
                touched={touched[`${type}${value?.id}`]}
                kind={type + value?.id}
                label={type}
                setFieldValue={setFieldValue}
                values={valuesForm}
                module
            />
            {type === "link" &&
                <Input
                    value={valuesForm.link}
                    label={t("pages.link")}
                    errors={errors.link}
                    touched={touched.link}
                    name={"link" + value?.id}
                    onClick={() => setFieldValue("link" + value?.id, '')}
                />
            }
        </div>
    )
}
import { HeaderSection } from 'components/HeaderSection/HeaderSection';
import s from './Delivery.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import { useDeleteDeliveryMutation, useGetDeliveryQuery } from '../../redux/apis/shopApi';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { useState } from 'react';
import { Preloader } from 'components/Preloader/Preloader';
import { ReactComponent as MoreVertical } from "assets/icons/more_vertical.svg";
import { MenuMini } from 'components/MenuMini/MenuMini';
import edit_icon from 'assets/icons/edit_icon.svg';
import deleteIcon2 from 'assets/icons/delete_icon2.svg';
import { CreateDeliveryModal } from './createModal/CreateDeliveryModal';

export const Delivery = () => {

    // ### State

    const [createModal, setCreateModal] = useState<any>(false)
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [idMenu, setIdMenu] = useState<number>();
    const {t} = useTranslation()

    const {data: delivery, isFetching} = useGetDeliveryQuery({})
    const [deleteDelivery, {isLoading: isDeleteLoading}] = useDeleteDeliveryMutation()
    // ### Effects

    // ### Functions

    function handleEditCategory(id: any){
        const el = delivery?.find((el: any) => el.id === id)
        setCreateModal(el)
    }

    // ### Views
    const section_items = [
        { icon: edit_icon, text: t("general.edit"), callback: () => {handleEditCategory(idMenu)} },
        { icon: deleteIcon2, text: t("general.delete"), callback: () => {deleteDelivery({id: idMenu})}}
    ];

    const categoriesView = delivery?.map((del: any, index: number) => {
        return(
            <div className={s.category} key={index}>
                <div className={s.tools}>
                    <MoreVertical className={s.more_content} onClick={() => {
                        setIdMenu(del.id);
                        setOpenMenu(!openMenu);
                    }} />
                    <MenuMini
                        items={section_items}
                        open={openMenu && idMenu === del.id}
                        close={setOpenMenu}
                        section
                    />
                </div>
                <div className={s.row}>
                    <span className={s.title}>Name</span>
                    <span className={s.value}>{del.name}</span>
                </div>
                <div className={s.row}>
                    <span className={s.title}>Description</span>
                    <span className={s.value}>{del.description}</span>
                </div>
            </div>
        )
    })

    return(
        <div className={s.wrapper}>
            <HeaderSection
                title={t("shop.delivery_page_title")}
                buttonText={t("dashboard.customize")}
                icon={<Brush />}
                disabled
            />

            <div className={s.content}>
                {categoriesView}
            </div>

            <div className={s.footer}>
                <PrimaryButton 
                    text={t('shop.add_delivery')} 
                    type={undefined}
                    onClick={()=>{setCreateModal(true)}}
                />
            </div>

            { createModal ? <CreateDeliveryModal data={createModal} close={()=>setCreateModal(false)}/> : null }
            { (isFetching || isDeleteLoading) ? <Preloader/> : null }
        </div>
    )
}
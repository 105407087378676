import {handleCopyText} from "utils/copyText";
import React, {useState} from "react";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete_icon2.svg";
import s from "./ListStorage.module.scss";

type ListStorageProps = {
    files: any;
    setDeletedId?: React.Dispatch<React.SetStateAction<number | undefined>>;
    setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ListStorage = ({files, setDeletedId, setShowDeleteModal}: ListStorageProps) => {
    const [isCopied, setIsCopied] = useState<any>({done: false, id: null});

    return (
        <div className={s.list}>
            {files?.map((file: any) => (
                <div className={s.card}>
                    <div className={s.card_top}>
                        {
                            (isCopied?.done && isCopied?.id === file?.id) ? (
                                <>
                                    <span>Success!</span>
                                    <CheckIcon className={s.check}/>
                                </>

                            ) : (
                                <>
                                    <p onClick={() => handleCopyText(file, setIsCopied)}>Copy link</p>
                                    <CopyIcon className={s.copy} onClick={() => handleCopyText(file, setIsCopied)}/>
                                </>
                            )
                        }

                        {(setDeletedId && setShowDeleteModal) &&
                            <DeleteIcon className={s.delete} onClick={() => {
                                setDeletedId(file?.id);
                                setShowDeleteModal(true);
                            }}/>
                        }

                    </div>


                    <div className={s.card_image}>
                        <img
                            src={file?.path}
                            alt="Compressed Preview"
                        />
                    </div>
                </div>
            ))}

        </div>
    )
}
import * as yup from "yup";

export const AdvertiserImageBlobValidation = yup.mixed()
    .test(
        'is-image',
        'Файл не является изображением',
        (value) => {
            if (!value) return false;
            if (!(value instanceof Blob)) return false;
            return value.type.startsWith('image/');
        }
    );

export const isValidJSONFormat = (str: string): boolean => {
    const regex = /^[\],:{}\s]*$/;
    return regex.test(
        str.replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|\d+([\.]\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    );
};
import s from './AddElementToArray.module.scss'
import React from "react";
import {Back} from "components/Back/Back";
import {useTranslation} from "react-i18next";
import {Form, Formik, FormikErrors, FormikHelpers, FormikTouched, FormikValues} from "formik";
import cn from "classnames";
import {DropDown} from "components/DropDown/DropDown";
import {useSelector} from "react-redux";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useStatusContext} from "components/StatusProvider";
import {useAddToArrayModuleMutation} from "../../../../../../redux/apis/modulesApi";
import {goToModule} from "../../../../../../utils/goToModule";
import {Preloader} from "../../../../../../components/Preloader/Preloader";
import {Input} from "../../../../../../components/Input/Input";

type AddElementToArrayProps = {
    setElementOfArray:  React.Dispatch<any>;
    elementOfArray: any;
    moduleId: number | undefined;
    refetchGetSection: any;
}

type DropdownProps = {
    value: any;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    setFieldValue: any;
    values:  FormikValues;
}

export const AddElementToArray = ({ setElementOfArray, elementOfArray, moduleId, refetchGetSection }: AddElementToArrayProps) => {
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);
    const { openStatus } = useStatusContext();
    const [addToArray, {isLoading: isLoadingAddToArray}] = useAddToArrayModuleMutation();

    if (langs.isFetching || isLoadingAddToArray) return <Preloader />

    const classType = (type: string) => cn(s.main_info, {
        [s.type_style]: type === "text" || type === "image" || type === "video"||  type === "link",
        [s.type_array]: type === "array",
    });

    const onSubmit = async (values: any, { resetForm }: FormikHelpers<any>) => {
        let copyArray = elementOfArray?.element;

        const getNewValues = (id: number) => {
            let valuesLang: any = [];

            langs?.forEach((l: any) => {
                for (let val in values) {
                    const isLink = val === "link";
                    if (isLink) {
                        copyArray= { ...copyArray, link: values[val]}
                    }

                    if (val.includes(l.key) && val.includes(String(id))) {
                        if (val.includes("image") || val.includes("video")) {
                            valuesLang.push({
                                file: values[val],
                                lang_key: l.key
                            })
                        } else {
                            valuesLang.push({
                                value: values[val],
                                lang_key: l.key
                            })
                        }

                    }
                }
            })

            return valuesLang;
        }

        const processValues = (values: any[]): any => {
            return values?.map((element: any) => {
                if (element?.type !== "object" && element?.type !== "array") {
                    return { ...element, values: getNewValues(element.id) };
                } else {
                    if (Array.isArray(element.values)) {
                        return { ...element, values: processValues(element.values) };
                    }

                    return element
                }
            });
        };

        const updatedValues = processValues(copyArray?.values || []);
        copyArray = { ...copyArray, values: (copyArray?.type === "array" || copyArray?.type === "object") ? updatedValues: getNewValues(copyArray.id) };

        const result: any = {
            module_id: moduleId,
            module: copyArray
        }

        const response: any = await addToArray(result).unwrap();

        if (response.message === "ok") {
            openStatus('success', "status.operationSuccessful");
            setElementOfArray({show: false});
            refetchGetSection();
            resetForm();
            goToModule(moduleId);
        } else {
            openStatus('error', "status.error");
        }
    }

    const renderMainInfo = ({value, errors, touched, setFieldValue, values}: DropdownProps) => {
        return (
            <div className={classType(value?.type)}>
                <p className={s.name_type}>Name: {value?.name}</p>
                {(value?.type === "object" || value?.type === "array") && <p className={s.type}>{value?.type}</p>}
                {(value?.type === "object" || value?.type === "array") &&
                    value?.values?.map((val: any) => {
                        if (val?.values) {
                            return renderMainInfo({
                                value: val,
                                errors,
                                touched,
                                setFieldValue,
                                values
                            });
                        }
                    })
                }
                {(value?.type !== "array" && value?.type !== "object") && (
                    <>
                        <DropDown
                            langs={langs}
                            errors={errors}
                            touched={touched}
                            kind={value?.type + value.id}
                            label={value?.type}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                        {value?.type === "link" &&
                            <Input
                                value={value.link}
                                label={t("pages.link")}
                                errors={errors.link}
                                touched={touched.link}
                                name={"link"}
                                onClick={() => setFieldValue("link")}
                            />
                        }
                    </>

                )}
            </div>
        )
    }

    return (
        <div className={s.container}>
            <Back close={setElementOfArray} text={t("blocksModal.backToBlocks")} />
            <div className={s.form}>
                <h2>{t("general.addANewItem")}</h2>

                <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => {
                            return (
                                <Form className={s.container_elements}>
                                    {elementOfArray?.element?.type !== "array" && elementOfArray?.element?.type !== "object" ?
                                      renderMainInfo({
                                            value: elementOfArray?.element,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            values
                                        })
                                    : Array.isArray(elementOfArray?.element?.values) &&
                                        elementOfArray?.element?.values?.map((val: any, index: number) => {
                                            if (val?.values) {
                                                return renderMainInfo({
                                                    value: val,
                                                    errors,
                                                    touched,
                                                    setFieldValue,
                                                    values
                                                });
                                            }
                                        })}
                                    <div className={s.wrap_buttons}>
                                        <PrimaryButton
                                            text={t("general.confirm")}
                                            type={"submit"}
                                            icon={<Check/>}
                                        />
                                        <SecondaryButton
                                            text={t("general.cancel")}
                                            type={"button"}
                                            white
                                            onClick={() => setElementOfArray(false)}
                                        />
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";
import s from "./Blog.module.scss";
import {Card} from "./Card/Card";
import {useEffect, useState} from "react";
import {AddBlog} from "./AddBlog/AddBlog";
import {useGetBlogsQuery} from "../../redux/apis/blog";
import {Preloader} from "components/Preloader/Preloader";

export const Blog = () => {
    const { t } = useTranslation();
    const [isAddBlog, setIsAddBlog] = useState<any>({show: false, blog: false});
    const {data: blogs, isFetching: isFetchingBlogs, refetch} = useGetBlogsQuery({});

    useEffect(() => {
        refetch()
    }, []);

    if (isAddBlog?.show) {
        return (
            <AddBlog setIsAddBlog={setIsAddBlog} blog={isAddBlog?.blog} />
        )
    }

    return (
        <div className={s.wrap_blog}>
            <HeaderSection
                title={t("tabBar.blog")}
                buttonText={"Add blog"}
                onClick={() => setIsAddBlog({show: true})}
            />

            <div className={s.list}>
                {blogs?.map((blog: any) => (
                    <Card blog={blog} setIsAddBlog={setIsAddBlog} />
                ))}
            </div>
            {isFetchingBlogs && <Preloader />}
        </div>
    )
}
import { useRef, useState } from 'react';
import s from './ImagesUploader.module.scss';
import { ReactComponent as SvgPlus } from "assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete_image.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {base64ToBlob, blobToBase64} from "utils/getImages";
import {AdvertiserImageBlobValidation} from "utils/validation";
import {compressorFiles} from "utils/compressorFiles";
import loaderIcon from 'assets/icons/loader.svg';

type Props = {
    state: any;
    setState: any;
}

export const ImagesUploader = ({ state, setState}: Props) => {
    const [loading, setLoading] = useState(false)

    const img_input: any = useRef<HTMLInputElement>(null)

    function previewImage() {
        setLoading(true);

        if (img_input?.current?.files?.length > 0) {
            const reader = new FileReader();
            reader.onload = async function (e) {
                const file = img_input.current?.files[0];
                if (file) {
                    try {
                        const compressImg = await compressorFiles({
                            compressFiles: file,
                            word: () => "image",
                        });

                        if (compressImg) {
                            validation(compressImg);
                        }
                    } catch (error) {
                        console.error('Error compressing file:', error);
                    }
                }
                setLoading(false);
            };

            reader.readAsDataURL(img_input.current.files[0]);
        } else {
            console.log('No files selected');
            setLoading(false);
        }
    }

    function validation(value: any){
        if(AdvertiserImageBlobValidation){
            AdvertiserImageBlobValidation.validate(value)
                .then(async () => {
                    const currenFile = {
                        file: `${await blobToBase64(value)}`,
                        mimetype: value.type
                    }
                    setState((prev: any) => { return {...prev, images: [...prev.images, currenFile]} })
                })
                .catch((result: any) => {
                    console.log(result);
                })
        }
    }

    function reorder(list: any, startIndex: any, endIndex: any){
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    async function onDragEnd(result: any){
        if (!result.destination) {
            return
        }
        const sortedList = reorder(
            state.images,
            result.source.index,
            result.destination.index
        )

        setState((prev: any) => {return {...prev, images: sortedList} })
    }

    function deleteImage(elementIndex: any){
        setState((prev: any) => {return {...prev, images: prev.images.filter((el: any, index: number) => elementIndex !== index)} })
    }

    return(
        <div className={s.wrap_imageUploader}>
            <span className={s.title}>Add pictures</span>
            <div className={s.images_wrapper}>
                <div className={s.add_button}>
                    <SvgPlus />
                    <span className={s.add}>ADD PIC</span>
                    <span className={s.mimetypes}>PNG, JPG, WEBP</span>
                    {loading ?
                        (
                            <>
                                <div className={s.white_bg}></div>
                                <img src={loaderIcon} className={s.loading} />
                            </>
                        )
                        : null }
                    <input disabled={loading} type="file" accept="image/*" ref={img_input} onChange={()=>previewImage()}/>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable' direction="horizontal">
                        {(provided: any) => (
                            <div className={s.selected_images} ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                    state?.images?.map((el: any, index: number) => {
                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={index.toString()}
                                                index={index}
                                            >
                                                {(provided: any) => (
                                                    <div className={s.image} key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <img
                                                            src={
                                                                el.file
                                                                    ? URL.createObjectURL(base64ToBlob(el.file, el.mimetype) || new Blob())
                                                                    : el
                                                            }
                                                            alt="photo"
                                                        />
                                                        <div className={s.delete_wrapper} onClick={()=>deleteImage(index)}>
                                                            <DeleteIcon />
                                                        </div>
                                                        {
                                                            !index &&
                                                            <div className={s.main}>
                                                                <span>Main photo</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        </div>
    )
}
import { HeaderSection } from "components/HeaderSection/HeaderSection";
import s from "./Storage.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";
import { compressorFiles } from "utils/compressorFiles";
import {useDeleteFileMutation, useFilesUploadMutation, useGetFilesQuery} from "../../redux/apis/mediaApi";
import { blobToBase64 } from "utils/getImages";
import {useStatusContext} from "components/StatusProvider";
import {Preloader} from "components/Preloader/Preloader";
import questionMark from "assets/images/questionMark.webp";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useTranslation} from "react-i18next";
import {ListStorage} from "components/ListStorage/ListStorage";

export const Storage = () => {
    const [filesUpload] = useFilesUploadMutation();
    const [deleteFile] = useDeleteFileMutation();
    const { openStatus } = useStatusContext();
    const {data: files, isFetching, refetch} = useGetFilesQuery({});
    const { t, i18n } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deletedId, setDeletedId] = useState<number>();
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

    useEffect(() => {
        refetch();
    }, []);

    const handleButtonClick = () => {
        const fileInput = document.getElementById("fileUpload") as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    };

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        setIsLoadingFile(true)
        const files = event.target.files;
        if (!files || files.length === 0) return;

        const word = (key: string) => key;

        const compressedFiles = await compressorFiles({
            compressFiles: Array.from(files),
            image: true,
            word,
        });

        if (compressedFiles && compressedFiles.length > 0) {
            const base64File = await blobToBase64(compressedFiles[0]);
            const result = await filesUpload({
                file: base64File,
            });

            if (result?.data?.message === "File created successful") {
                setIsLoadingFile(false)
                openStatus('success', "status.operationSuccessful");
            } else {
                setIsLoadingFile(false)
                openStatus('error', "status.error");
            }

            console.log("Завантаження успішне", result);
        } else {
            console.log("The file exceeds the allowable size or is of an incorrect type!");
        }
    };

    const handleDeleteFile = async (id: number) => {
        const result = await deleteFile({
            id: id
        })

        if (result?.data?.message === "File deleted successful") {
            openStatus('success', "status.operationSuccessful");
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.wrap_media}>
            {(isFetching || isLoadingFile) && <Preloader />}
            <HeaderSection
                title={"Storage"}
                buttonText={"Add file"}
                onClick={handleButtonClick}
            />

            <input
                id="fileUpload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
            />

            <ListStorage
                files={files}
                setDeletedId={setDeletedId}
                setShowDeleteModal={setShowDeleteModal}
            />

            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                image={questionMark}
                description={`${t("general.areYouSure")}? ${t("general.itsContents")}`}
                deletedId={deletedId}
                buttonRight={handleDeleteFile}
            />
        </div>
    );
};

import { TextFields } from 'components/TextFileds/TextFileds';
import s from './CreateCategoryModal.module.scss';
import { useEffect, useState } from 'react';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'components/SecondaryButton/SecondaryButton';
import { InputImage } from 'components/InputImage/InputImage';
import { useCreateCategoryMutation, useEditCategoryMutation } from '../../../redux/apis/shopApi';
import { Preloader } from 'components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';

type FormType = {
    name: {[key: string]: string},
    description: {[key: string]: string},
    image: string | undefined
}

export const CreateCategoryModal = ({close, data}: any) => {

    // ### State
    const [form, setForm] = useState<FormType>({
        name: {},
        description: {},
        image: undefined
    })
    const {t} = useTranslation()

    const [createCategory, {isLoading: isCreateLoading}] = useCreateCategoryMutation()
    const [editCategory, {isLoading: isEditLoading}] = useEditCategoryMutation()

    // ### Effects
    useEffect(()=>{
        console.log("form", form);
    },[form]);

    useEffect(()=>{
        if(data?.id){
            setForm({
                name: data.name,
                description: data.description,
                image: undefined
            })
        }
    },[data]);

    // ### Functions

    async function handleCreate(){
        if(!form.name){
            alert('Name error')
            return 
        }
        if(!form.name){
            alert('Description error')
            return 
        }
        const action = data?.id ? editCategory : createCategory
        const response = await action({
            name: Object.entries(form.name)?.map(el => ({lang_key: el[0], value: el[1]})), 
            description: Object.entries(form.description)?.map(el => ({lang_key: el[0], value: el[1]})), 
            image: form.image,
            id: data?.id
        }).unwrap()
       
        if(response.message === "Category created successful" || response.message === "Category updated successful"){
            close()
        }
    }

    // ### Views

    return(
        <div className={s.modal_wrapper}>
            <div className={s.filter}></div>

            <div className={s.modal}>


                <TextFields 
                    values={form.name}
                    title="Name"
                    changeValue={ (val)=>{ setForm(prev => ({...prev, name: {...prev.name, ...val}})) } }
                />

                <TextFields 
                    values={form.description}
                    title="Description"
                    changeValue={ (val)=>{ setForm(prev => ({...prev, description: {...prev.name, ...val}})) } }
                />

                <InputImage 
                    title="Image"
                    setImage={ (val)=>{ setForm(prev => ({...prev, image: val})) } }
                    image={data?.image}
                />

                <div className={s.footer}>
                <PrimaryButton 
                        text={t("general.confirm")} 
                        type="button"
                        onClick={handleCreate}
                    />
                    <SecondaryButton 
                        text={t("general.cancel")} 
                        type="button"
                        onClick={close}
                    />
                </div>

            </div>
            {(isCreateLoading || isEditLoading) ? <Preloader /> : null}
        </div>
    )
}
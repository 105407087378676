import s from "./ModuleValuesItem.module.scss";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import {ReactComponent as ArrowTools} from "assets/icons/arrow_tools.svg";
import {ReactComponent as TrashIcon} from "assets/icons/trash.svg";
import questionMarkImage from "assets/images/questionMark.webp";
import {ReactComponent as EditIcon} from "assets/icons/edit_icon.svg";
import {ReactComponent as PlusIcon} from "assets/icons/plus.svg";

import {useSelector} from "react-redux";
import { useDeleteModuleMutation} from "../../../../../../redux/apis/modulesApi";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useStatusContext} from "components/StatusProvider";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import {Preloader} from "components/Preloader/Preloader";

type ModuleValuesItemProps = {
    object: any;
    sectionId: number;
    refetchGetSection: any;
    setEditModule: React.Dispatch<any>;
    setIsAddModule: React.Dispatch<boolean>;
    setModuleId: React.Dispatch<number>;
    setIdSection: React.Dispatch<number>;
    setElementOfArray: React.Dispatch<any>;
    setScrollId: React.Dispatch<number>;
    hidden: any;
    setHidden: React.Dispatch<any>;
    currentModule: number;
    setCurrentModule: React.Dispatch<number>;
}

type RenderModuleValuesProps = {
    values: any[];
};

type ModuleValuesItemsProps = {
    values: any;
};

export const ModuleValuesItem = ({
                                     object,
                                     sectionId,
                                     refetchGetSection,
                                     setEditModule,
                                     setIsAddModule,
                                     setModuleId,
                                     setIdSection,
                                     setElementOfArray,
                                     setScrollId,
                                     hidden,
                                     setHidden,
                                     currentModule,
                                     setCurrentModule
}: ModuleValuesItemProps) => {
    const { t } = useTranslation();

    const langs = useSelector((store: any) => store.main.langs);
    const [langState, setLangState] = useState(langs?.[0]?.key);
    const [deleteModule, {isLoading: isLoadingDeleteModule}] = useDeleteModuleMutation();
    const [idDeletedModule, setIdDeletedModule] = useState<number>(0);
    const [showModuleDelete, setShowModuleDelete] = useState<boolean>(false);
    const { openStatus } = useStatusContext();
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

    if (langs.isFetching || isLoadingDeleteModule) return <Preloader />

    const handleDeleteModule = async (sectionId: number, moduleId: number) => {
        try {
            const response = await deleteModule({ id: moduleId, section_id: sectionId }).unwrap();
            if (response.message === "ok") {
                openStatus('success', "status.operationSuccessful");
                setShowModuleDelete(false);
                refetchGetSection()
            } else {
                openStatus('error', "status.error");
            }
        } catch (error) {
            console.error('Error deleting module:', error);
        }
    };

    const openModalDelete = (moduleId: number) => {
        setIdDeletedModule(moduleId);
        setShowModuleDelete(true);
    }

    const classType = (type: string) => cn(s.type, {
        [s.type_array]: type === "array",
        [s.type_object]: type === "object",
    });

    const handleArrowClick = (id: number) => {
        setHidden((prevState: any) =>
            prevState.includes(id)
                ? prevState.filter((item: any) => item !== id)
                : [...prevState, id]
        );
    };

    const goToAddModule = () => {
        setIsAddModule(true);
        setModuleId(object?.type_id);
        setIdSection(sectionId);
    }

    const setting = () => {
        return (
            <div className={s.setting}>
                <ArrowTools className={s.setting_arrow}
                            // onClick={() => handleArrowClick(object?.type_id)}
                />
                <PlusIcon onClick={goToAddModule} />
            </div>
        )
    }

    const RenderModuleValues = ({ values }: RenderModuleValuesProps) => {
        const renderValueItem = (value: any, index: number, containerClass: string) => (
            <div className={`${containerClass} ${(value?.type !== "array" && value?.type !== "object") && s.main_type} anchor-${value?.id}`} key={index}>
                <div className={`${s.module_values_item} ${!hidden?.includes(value?.id) && s.hide_1} anchor-${value?.id}`}>
                    <div className={s.item_name} onClick={() => handleArrowClick(value?.id)}>
                        <div className={s.main_info}>
                            <div className={s.data}>
                                <p>{t("pagesModal.name")}:</p>
                                <span>{value?.name}</span>
                            </div>
                            <div className={s.data}>
                                <p>{t("pages.marker")}:</p>
                                <span>{value?.marker}</span>
                            </div>

                            {value?.link && <div className={s.data}>
                                <p>{t("pages.link")}:</p>
                                <span>{value?.link}</span>
                            </div>}
                        </div>
                        <span className={classType(value?.type)}>{value?.type}</span>
                        <div className={s.setting}>
                            <ArrowTools className={s.setting_arrow}
                                        // onClick={() => handleArrowClick(value?.id)}
                            />
                            {(value?.type !== "array" && value?.type !== "object") &&
                                <EditIcon className={s.setting_edit} onClick={() => {
                                    setEditModule({show: true, element: value})
                                    setScrollId(value?.id)
                                }} />
                            }
                            {value?.type === "array" &&
                                <PlusIcon onClick={() => {
                                    setElementOfArray({show: true, element: value?.values?.[0]})
                                    setModuleId(value?.id)
                                }} />
                            }
                        </div>
                    </div>
                    {value?.values && <RenderModuleValues values={value?.values} />}
                </div>
            </div>
        );

        const renderItems = (values: any) => {
            if (Array.isArray(values)) {
                const itemsForModule = values?.filter((value) => !value?.values);

                return (
                    <>
                        {itemsForModule.length > 0 && <ModuleValuesItems values={itemsForModule} />}

                        {values.map((value, index) => {
                            return value?.values
                                ? renderValueItem(value, index, s.container_first)
                                : null;
                        })}

                    </>
                );
            } else if (typeof values === 'object' && values !== null) {
                const itemsForModule = Object.values(values).filter((value: any) => !value?.values);

                return (
                    <>
                        {itemsForModule.length > 0 && <ModuleValuesItems values={itemsForModule} />}

                        {Object.values(values).map((value: any, index) =>
                            value?.values ? renderValueItem(value, index, s.container_second) : null
                        )}
                    </>
                );
            }

            return null;
        };

        return <>{renderItems(values)}</>;
    };

    const ModuleValuesItems = ({ values }: ModuleValuesItemsProps) => {
        const isImagePath = (value: string) => value?.startsWith('storage/images/');
        const isVideoPath = (value: string) => value?.startsWith('storage/video/');

        return (
            <div className={s.module_values}>
                <div className={s.container_third}>
                    <div>
                        {values?.some((v: any) => isImagePath(v?.value) || isVideoPath(v?.value)) ? (
                            <div className={s.image_wrapper}>
                                {values?.map((v: any, index: number) => {
                                    if (isImagePath(v?.value)) {
                                        return (
                                            <div key={index} className={s.media_wrapper}>
                                                {v?.lang_key}
                                                <img
                                                    src={`${API_ADDRESS}${v?.value}`}
                                                    alt={`image-${index}`}
                                                    className={s.image}
                                                />
                                            </div>
                                        );
                                    } else if (isVideoPath(v?.value)) {
                                        return (
                                            <div key={index} className={s.media_wrapper}>
                                                <video
                                                    src={`${API_ADDRESS}${v?.value}`}
                                                    controls
                                                    className={s.video}
                                                >
                                                    Ваш браузер не підтримує відео тег.
                                                </video>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        ) : (
                            <div className={s.content}>
                                <div className={`${s.text_block}`}>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                values?.filter(
                                                    (value: any) =>
                                                        value?.lang_key === String(langState)
                                                )?.[0]?.value || '',
                                        }}
                                    ></p>
                                </div>
                                <div className={s.lang_block}>
                                    {langs?.map((el: any) => (
                                        <div
                                            key={el.key}
                                            className={`${s.lang_item} ${
                                                el.key === String(langState) && s.lang_item_active
                                            }`}
                                            onClick={() => setLangState(el.key)}
                                        >
                                            <div className={s.lang}>{el.key}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={s.modules}>
            <div className={s.container}>
                <div className={`${s.module} ${!hidden?.includes(object?.type_id) && s.hide} anchor-${object?.type_id}`}>
                    <div className={s.module_header} onClick={() => handleArrowClick(object?.type_id)}>
                        <div className={s.module_header_flex} >
                            {object?.values?.map((module: any, index:number) => (
                                <SecondaryButton
                                    text={module?.name}
                                    type={"button"}
                                    className={`${s.button_marker} ${ index === currentModule && s.button_marker_active}`}
                                    onClick={() => {
                                        setCurrentModule(index)
                                        hidden.includes(object?.type_id) && handleArrowClick(object?.type_id)
                                    }}
                                    icon={<TrashIcon onClick={() => openModalDelete(module?.id)} />}
                                />
                            ))}
                        </div>

                        {setting()}
                    </div>

                    <div className={s.module_values}>
                        {Array.isArray(object?.values?.[currentModule]?.values) ? (
                            <div className={s.module_values_item}>
                                <RenderModuleValues values={object?.values?.[currentModule]?.values} />
                            </div>
                        ) : object?.values?.[currentModule]?.values ? (
                            <RenderModuleValues values={Object.values(object?.values?.[currentModule]?.values)} />
                        ) : null}
                    </div>

                </div>
            </div>

            <ModalGeneral
                description={`${t("general.areYouSure")} module? ${t("general.itsContents")}`}
                image={questionMarkImage}
                buttonRight={() => handleDeleteModule(sectionId, idDeletedModule)}
                setShowModal={setShowModuleDelete}
                showModal={showModuleDelete}
                textRightButton={t("general.delete")}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
            />
        </div>
    )
}
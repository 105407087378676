import s from './CreateDeliveryModal.module.scss';
import { useEffect, useState } from 'react';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'components/SecondaryButton/SecondaryButton';
import { useCreateDeliveryMutation, useEditDeliveryMutation } from '../../../redux/apis/shopApi';
import { Preloader } from 'components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';

type FormType = {
    name: string | undefined,
    description: string | undefined
}

export const CreateDeliveryModal = ({close, data}: any) => {

    // ### State
    const [form, setForm] = useState<FormType>({
        name: undefined,
        description: undefined
    })
    const {t} = useTranslation()

    const [createCategory, {isLoading: isCreateLoading}] = useCreateDeliveryMutation()
    const [editCategory, {isLoading: isEditLoading}] = useEditDeliveryMutation()

    // ### Effects

    useEffect(()=>{
        console.log("##### form", form);
    },[form]);

    useEffect(()=>{
        if(data?.id){

            setForm({
                name: data.name,
                description: data.description
            })
        }
    },[data]);

    // ### Functions

    async function handleCreate(){
        if(!form.name){
            alert('error')
            return 
        }
        const action = data?.id ? editCategory : createCategory
        const response = await action({
            name: form.name, 
            description: form.description,
            id: data?.id
        }).unwrap()
       
        if(response.message === "Delivery created successful" || response.message === "Delivery updated successful"){
            close()
        }
    }

    // ### Views

    return(
        <div className={s.modal_wrapper}>
            <div className={s.filter}></div>

            <div className={s.modal}>

                <div className={s.row}>
                    <span className={s.title}>Title</span>
                    <input 
                        type="text" 
                        value={form.name}
                        onChange={ (ev)=>{ setForm(prev => ({...prev, name: ev.target.value})) } }
                    />
                </div>

                <div className={s.row}>
                    <span className={s.title}>Description</span>
                    <input 
                        type="text" 
                        value={form.description}
                        onChange={ (ev)=>{ setForm(prev => ({...prev, description: ev.target.value})) } }
                    />
                </div>

                <div className={s.footer}>
                    <PrimaryButton 
                        text={t("general.confirm")} 
                        type="button"
                        onClick={handleCreate}
                    />
                    <SecondaryButton 
                        text={t("general.cancel")}  
                        type="button"
                        onClick={close}
                    />
                </div>

            </div>
            {(isCreateLoading || isEditLoading) ? <Preloader /> : null}
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import s from './DropDown.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import imageCompression from "browser-image-compression";

import { Field, FormikErrors, FormikValues } from "formik";
import 'react-quill/dist/quill.snow.css';
import translateIcon from "assets/icons/translate.webp";
import {translateText} from "utils/translateText";
import {useStatusContext} from "../StatusProvider";
import TinyEditor from 'components/TinyEditor/TinyEditor';

type DropDownProps = {
    langs: { key: string }[];
    errors: any;
    touched: any;
    kind: string;
    label: string;
    module?: boolean;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
    values?: FormikValues;
};

export const DropDown = ({ langs, errors, touched, kind, label, module, setFieldValue, values }: DropDownProps) => {
    const [hideStates, setHideStates] = useState<Record<string, boolean>>({});
    const [selectedLang, setSelectedLang] = useState<string>(langs[0]?.key || '');
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const [translateValues, setTranslateValues] = useState<any>();
    const { openStatus } = useStatusContext();
    const [isLoadingTranslate, setIsLoadingTranslate] = useState<boolean>(false);

    function getCurrentValue(){
        let value
        for(let i=0; i<langs?.length; i++){
            if(translateValues[langs?.[i].key]?.length){
                value = translateValues[langs?.[i].key]
                break
            }
        }
        return value
    }

    const handleTranslate = async () => {
        try {
            setIsLoadingTranslate(true)
            const result = await translateText(getCurrentValue(), langs?.map((l: any) => l.key));
            setTranslateValues(result);
            setIsLoadingTranslate(false)
        } catch (error) {
            openStatus('error', "status.notPicture");
            setIsLoadingTranslate(false)
            console.error("Error during translation:", error);
        }
    };

    useEffect(() => {
        setHideStates(langs?.reduce((acc, lang) => ({ ...acc, [lang.key]: true }), {}));
    }, [langs]);

    const toggleHide = (key: string) => {
        setHideStates(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleLanguageChange = (langKey: string) => {
        setSelectedLang(langKey);
    };

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        key: string
    ) => {
        const file = event.target.files?.[0];

        if (file && setFieldValue) {
            try {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1024,
                    useWebWorker: true,
                };

                const compressedFile = await imageCompression(file, options);

                const reader = new FileReader();
                reader.onloadend = () => setFieldValue(`${kind}-${key}`, reader.result as string);
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error("Помилка стиснення файлу:", error);
            }
        }
    };

    const renderMediaContent = (fieldName: string, value: string | undefined, type: "image" | "video") => {
        const src = (value && (value?.startsWith("https") || value?.startsWith("data"))) ? value : `${API_ADDRESS}${value}`;

        return (
            <div className={s.image_wrapper}>
                {(type === "image") ? <img src={src} alt="Upload" className={s.image} /> : <video src={src} className={s.image} controls />}
                <Field
                    className={s.change_input}
                    name={`${fieldName}-${type}`}
                    type="file"
                    accept={`${type}/*`}
                    onChange={(e: any) => handleFileUpload(e, fieldName.split('-')[1])}
                />
            </div>
        );
    };

    const renderContent = (v: { key: string }) => {
        const fieldName = `${kind}-${v.key}`;
        const value = values?.[fieldName];

        if (label === "image" || label === "video") {
            return renderMediaContent(fieldName, value, label);
        }

        return <Field
            name={fieldName}
            component={TinyEditor}
            translateValues={translateValues}
            setTranslateValues={setTranslateValues}
        />;
    };

    const renderErrors = (key: string) => {
        const errorKey = `${kind}-${key}`;
        return touched?.[errorKey] && errors?.[errorKey] && (
            <span className={s.error_text}>{errors[errorKey]}</span>
        );
    };

    return (
        <div className={s.dropdown}>
            <div className={s.languageButtons}>
                {typeof kind === "string" && (!kind.includes("image") && !kind.includes("video")) && langs?.map(v => (
                    <div
                        key={v.key}
                        onClick={() => handleLanguageChange(v.key)}
                        className={`${s.languageButton} ${selectedLang === v.key ? s.selected : ''}`}
                    >
                        {v.key}
                        {(values?.[`${kind}-${v.key}`] && values?.[`${kind}-${v.key}`] !== "<p><br></p>") &&
                            <CheckIcon className={s.check_icon}/>}
                    </div>
                ))}
                {typeof kind === "string" && (!kind.includes("image") && !kind.includes("video")) &&
                    (isLoadingTranslate ? <LoadingIcon className={s.loadingIcon} /> :
                        <img
                            src={translateIcon}
                            alt={"translateIcon"}
                            className={s.translateIcon}
                            onClick={handleTranslate}
                        />
                    )
                }
            </div>

            {typeof kind === "string" && (kind.includes("image") || kind.includes("video")) ? (
                <div className={s.content_row}>
                    {langs?.map(v => (
                        <div key={v.key} className={s.flex}>
                            {!module && (
                                <div className={s.content_head}>
                                    <p>{label} <span>{v.key}</span></p>
                                </div>
                            )}
                            {renderContent(v)}
                            {renderErrors(v.key)}
                        </div>
                    ))}
                </div>
            ) : (
                langs?.map(v => (
                    <div
                        key={v.key}
                        className={`${s.content} ${!hideStates[v.key] ? s.hide : ''} ${v.key !== selectedLang ? s.none : ''}`}
                    >
                        {!module && (
                            <div className={s.content_head} onClick={() => toggleHide(v.key)}>
                                <p>{label} <span>{v.key}</span></p>
                                <ArrowTools className={s.arrow}/>
                            </div>
                        )}
                        {renderContent(v)}
                        {renderErrors(v.key)}
                    </div>
                ))
            )}
        </div>
    );
};

import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";
import s from "./Products.module.scss";
import React, {useEffect, useState} from "react";
import {Card} from "./components/Card/Card";
import {EditProduct} from "./components/EditProduct/EditProduct";
import {useGetCategoriesQuery, useGetProductsQuery} from "../../redux/apis/shopApi";
import {Preloader} from "components/Preloader/Preloader";
import {ModalGeneral} from "components/Modals/ModalGeneral";

interface EditPageState {
    open: boolean;
    categoryId: number | null;
    product: any;
}

export const Products = () => {
    const { t, i18n } = useTranslation();
    const {data: categories, isFetching} = useGetCategoriesQuery({})
    const [activeCategory, setActiveCategory] = useState<number>();
    const [editPage, setEditPage] = useState<EditPageState>({ open: false, categoryId: null, product: false });
    const {data: products, isFetching: isFetchingProducts} = useGetProductsQuery({categories: activeCategory}, {refetchOnMountOrArgChange: true})
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    useEffect(() => {
        if (editPage?.categoryId) {
            setActiveCategory(editPage?.categoryId)
        }
    }, [editPage?.categoryId]);

    useEffect(() => {
        if (categories) {
            setActiveCategory(categories?.[0]?.id);
        }
    }, [categories]);

    if (isFetching) return <Preloader />

    if (editPage.open) {
        return (
            <EditProduct
                setEditPage={setEditPage}
                categoryId={editPage?.categoryId}
                product={editPage?.product}
            />
        )
    }

    const addNewProduct = (value: any) => {
        setEditPage({open: true, categoryId: value?.id, product: false })
    }

    return (
        <section className={s.wrap_products}>
            <HeaderSection
                title={t("tabBar.products")}
                buttonText={t("shop.addProduct")}
                onClick={() => setShowAddModal(true)}
            />

            <div className={s.filter_buttons}>
                {categories?.map((cat: any) => (
                    <div className={`${s.button} ${cat.id === activeCategory ? s.button_active : ""}`}
                         onClick={() => setActiveCategory(cat.id)}>
                        {cat?.name?.[i18n.language]}
                    </div>
                ))}
            </div>

            <div className={s.list}>
                {isFetchingProducts &&  <Preloader />}
                {products?.length === 0 ? (<div>{t("shop.noProducts")}</div>):
                    products?.map((product: any) => (
                        <Card product={product} setEditPage={setEditPage}/>
                    ))
                }
            </div>

            <ModalGeneral
                buttonRight={addNewProduct}
                setShowModal={setShowAddModal}
                showModal={showAddModal}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.add")}
                title={t("shop.addProduct")}
                addProduct
            />
        </section>
    )
}
import React, {FC, useEffect, useState} from 'react'
import s from "./TinyEditor.module.scss"
import { Editor } from '@tinymce/tinymce-react'
import { FieldProps } from 'formik';
import {ModalStorage} from "../ModalStorage/ModalStorage";

interface TinyEditorProps {
	field: FieldProps['field'];
	form: FieldProps['form'];
	translateValues: any;
	setTranslateValues: React.Dispatch<any>;
}

const TinyEditor: FC<TinyEditorProps> = ({ field, form, translateValues, setTranslateValues  }) => {
	const key = field.name.split("-")[1];
	const [isOpenStorage, setIsOpenStorage] = useState<boolean>(false);


	useEffect(() => {
		if (translateValues) {
			Object.entries(translateValues).forEach(([langKey, value]) => {
				const fieldName = `${field.name.split("-")[0]}-${langKey}`;
				if (form.values[fieldName] !== value) {
					form.setFieldValue(fieldName, value);
				}
			});
		}
	}, [translateValues, form, field.name]);

	const handleChange = (content: string) => {
		form.setFieldValue(field.name, content);

		if (translateValues?.[key] !== content) {
			setTranslateValues((prevValues: any) => ({
				...prevValues,
				[key]: content,
			}));
		}
	};


	if (isOpenStorage) {
		return <ModalStorage setIsOpenStorage={setIsOpenStorage} />
	}

	return (
		<div className={s.wrapper}>
			<div className={`${s.storage} storage`} onClick={() => setIsOpenStorage(true)}>Storage</div>
			
			<Editor
				tinymceScriptSrc='/tinymce/tinymce.min.js'
				value={translateValues?.[key] ?? field.value}
				init={{
					branding: false,
					width: 'inherit',
					skin: "oxide-dark",
					content_css: "dark",
					height: 500,
					menubar: false,
					plugins: "image",
					toolbar:
						"styleselect | h1 h2 h3 | bold italic underline strikethrough | image link alignleft aligncenter alignright alignjustify | bullist outdent indent | removeformat",
					image_advtab: false,
					image_class_list: [
						{ title: 'Center', value: 'none' },
						{ title: 'Left', value: 'image_left' },
						{ title: 'Right', value: 'image_right' }
					],
					style_formats: [
						{title: 'image_left', selector: 'image_left', styles: {
							'float' : 'left',
							'margin': '0 10px 0 10px'
						}}
					],
					content_style: `
						body {
							background-color: #232428;
						}
					`
				}}
				onEditorChange={value => {
					handleChange(value)
				}}
			/>
		</div>
	)
}

export default TinyEditor



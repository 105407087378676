import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import s from './TabBar.module.scss';
import {
    useCreateTypeModuleMutation,
    useDeleteTypeModuleMutation,
    useGetModulesQuery
} from "../../redux/apis/modulesApi";
import logo from "assets/icons/logo.svg";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {ModalGeneral} from "../Modals/ModalGeneral";
import logOutIcon from "assets/modalsImg/logOut.webp";
import { ReactComponent as Dashboard } from "assets/icons/dashboard.svg";
import { ReactComponent as WebsitePages } from "assets/icons/websitePages.svg";
import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Language } from "assets/icons/language.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete_icon2.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ModulesIcon } from "assets/icons/modules.svg";
import { ReactComponent as DeliveryIcon } from "assets/icons/delivery.svg";
import { ReactComponent as ProductsIcon } from "assets/icons/products.svg";
import { ReactComponent as CategoryIcon } from "assets/icons/category.svg";
import { ReactComponent as CartOrangeIcon } from "assets/icons/cart_orange.svg";
import { ReactComponent as TruckOrangeIcon } from "assets/icons/truck_orange.svg";
import { ReactComponent as NotebookIcon } from "assets/icons/notebook.svg";
import { ReactComponent as NotebookOrangeIcon } from "assets/icons/notebookOrange.svg";
import { ReactComponent as AlbumIcon } from "assets/icons/album.svg";
import { ReactComponent as AlbumOrangeIcon } from "assets/icons/albumOrange.svg";

import {useTranslation} from "react-i18next";
import questionMark from "assets/images/questionMark.webp";

export const TabBar = () => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('userData')
        ? JSON.parse(localStorage.getItem('userData')!)
        : null;
    const { data: modules, refetch: modulesRefetch } = useGetModulesQuery({});
    const navigate = useNavigate();
    const [showModal, setShowModal]= useState<boolean>(false);
    const [hide, setHide] = useState<boolean>(true);
    const [hideShop, setHideShop] = useState<boolean>(true);

    const location = useLocation();
    const { t } = useTranslation();
    const [deleteTypeModule] = useDeleteTypeModuleMutation();
    const [ createTypeModule ] = useCreateTypeModuleMutation();
    const [showModalAddModule, setShowModalAddModule] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deletedId, setDeletedId] = useState<number>();
    const [deletedName, setDeletedName] = useState<string>("");

    if(!token) return <h1>Loading...</h1>;

    const openModalDeleteModule = (id: number, name: string) => {
        setShowDeleteModal(true);
        setDeletedId(id);
        setDeletedName(name);
    }

    const deleteModuleItems = async (id: string) => {
        try {
            const result = await deleteTypeModule({ id }).unwrap();
            console.log("Модуль успішно видалено:", result);
            modulesRefetch().then(() => {
                navigate("/");
            });
        } catch (error) {
            console.error("Помилка при видаленні модуля:", error);
        }
    }

    const handleCreateModule = async (name: string, marker: string) => {
        try {
            const randomId = Math.floor(Math.random() * 1000000000);

            await createTypeModule({
                name: name,
                type: {
                    type: "object",
                    name: name,
                    id: randomId,
                    marker: marker,
                    values: {},
                },
            }).unwrap();

            const updatedModules = await modulesRefetch().unwrap();

            const lastModuleId = updatedModules.modules?.[updatedModules.modules.length - 1]?.id;

            navigate(`/module/${lastModuleId}`);

            setShowModalAddModule(false);
        } catch (error) {
            console.error("Помилка при створенні модуля:", error);
        }
    };

    const modules_view = modules?.modules?.map((el: any) => {
        return (
            <Link
                key={el.id}
                to={`module/${el.id}`}
                className={`${s.menu_item} ${`/module/${el.id}` === location.pathname ? s.active : ""}`}
            >
                <ModulesIcon className={s.iconItem} />
                <span className={s.title_bar}>{el.name}</span>
                <DeleteIcon className={s.delete_icon} onClick={() => openModalDeleteModule(el.id, el.name)} />
            </Link>
        )
    });

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        navigate("/auth/");
        setShowModal(false);
    }

    const deletedElement =
        <>
            {t("general.areYouSure")} <span className={s.deletedElement}>{deletedName}</span>. {t("general.itsContents")}
        </>


    return (
        <>
            <section className={s.left_menu}>
                <img src={logo} alt={s.logo} className={s.logo}/>

                <div className={s.overflow}>
                    <div className={s.menu}>
                        <Link
                            to="/"
                            className={`${s.menu_item} ${"/" === location.pathname && s.active}`}
                        >
                            <Dashboard className={s.iconItem}/>
                            <span>{t("tabBar.homePage")}</span>
                        </Link>

                        <Link
                            to="/pages"
                            className={`${s.menu_item} ${"/pages" === location.pathname ? s.active : ""}`}
                        >
                            <WebsitePages className={s.iconItem}/>
                            <span>{t("pages.pagesName")}</span>
                        </Link>

                        <Link
                            to="/blog"
                            className={`${s.menu_item} ${"/blog" === location.pathname ? s.active : ""}`}
                        >
                            {`/blog` === location.pathname ? <NotebookOrangeIcon className={s.iconItem} /> :  <NotebookIcon className={s.iconItem} />}

                            <span>{t("tabBar.blog")}</span>
                        </Link>

                        <Link
                            to="/storage"
                            className={`${s.menu_item} ${"/storage" === location.pathname ? s.active : ""}`}
                        >
                            {`/storage` === location.pathname ? <AlbumOrangeIcon className={s.iconItem} /> :  <AlbumIcon className={s.iconItem} />}
                            <span>{"Storage"}</span>
                        </Link>

                        <div className={`${s.menu_main} ${hide ? s.hide : ''}`}>
                            <h2>
                                {t("tabBar.mainModules")}
                                <PlusIcon className={s.plus} onClick={() => setShowModalAddModule(true)}/>
                                <Arrow className={s.arrow} onClick={() => setHide(!hide)}/>
                            </h2>
                            {modules_view}
                        </div>

                        <div className={`${s.menu_main} ${hideShop ? s.hide : ''}`}>
                            <h2>
                                {t("tabBar.shop")}
                                <Arrow className={s.arrow} onClick={() => setHideShop(!hideShop)}/>
                            </h2>

                            <Link
                                to={`/category`}
                                className={`${s.menu_item} ${`/category` === location.pathname ? s.active : ""}`}
                            >
                                <CategoryIcon className={s.iconItem} />
                                <span className={s.title_bar}>{t("tabBar.category")}</span>
                            </Link>
                            <Link
                                to={`/delivery`}
                                className={`${s.menu_item} ${`/delivery` === location.pathname ? s.active : ""}`}
                            >
                                {`/delivery` === location.pathname ? <TruckOrangeIcon className={s.iconItem} /> :  <DeliveryIcon className={s.iconItem} />}
                                <span className={s.title_bar}>{t("tabBar.delivery")}</span>
                            </Link>
                            <Link
                                to={`/products`}
                                className={`${s.menu_item} ${`/products` === location.pathname ? s.active : ""}`}
                            >
                                {`/products` === location.pathname ? <CartOrangeIcon className={s.iconItem} /> : <ProductsIcon className={s.iconItem} />}
                                <span className={s.title_bar}>{t("tabBar.products")}</span>
                            </Link>
                        </div>

                        <div className={s.menu_general}>
                            <h2>{t("tabBar.general")}</h2>
                            {userData?.id > 1 ? null : (
                                <Link
                                    to="/users"
                                    className={`${s.menu_item} ${"/users" === location.pathname ? s.active : ""}`}
                                >
                                    <Users className={s.iconItem}/>
                                    <span>{t("dashboard.users")}</span>
                                </Link>
                            )}
                            {/*<Link*/}
                            {/*    to="/language"*/}
                            {/*    className={`${s.menu_item} ${"/language" === location.pathname ? s.active : ""}`}*/}
                            {/*>*/}
                            {/*    <Language className={s.iconItem}/>*/}
                            {/*    <span>{t("language.langs")}</span>*/}
                            {/*</Link>*/}

                        </div>
                    </div>

                    <div className={s.bottom_menu}>
                        <SecondaryButton
                            type="button"
                            text={t("tabBar.output")}
                            onClick={() => setShowModal(true)}
                            icon={<Logout/>}
                        />
                    </div>
                </div>
            </section>
            <ModalGeneral
                showModal={showModalAddModule}
                setShowModal={setShowModalAddModule}
                title={t("modules.addInfo")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                buttonRight={handleCreateModule}
                fieldBarModule
            />
            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                image={questionMark}
                description={deletedElement}
                deletedId={deletedId}
                buttonRight={deleteModuleItems}
            />
            <ModalGeneral
                showModal={showModal}
                setShowModal={setShowModal}
                image={logOutIcon}
                buttonRight={logOut}
                title={t("tabBar.wantToCome")}
                textLeftButton={t("tabBar.stay")}
                textRightButton={t("tabBar.exit")}
            />

        </>

    )
}
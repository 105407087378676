import imageCompression from "browser-image-compression";

type Props = {
    compressFiles: any;
    image?: boolean;
    word: any;
}

export const compressorFiles = async ({ compressFiles, image = false, word }: Props) => {
    let files: any = compressFiles;
    let flagSize: any = false;
    let flagType: any = false;

    if (Array.isArray(files)) {
        for (let i = 0; i < files.length; i++) {
            const {
                file,
                flagSize: flagS,
                flagType: flagT
            }: any = await typesFile({ file: files[i], flagSize, flagType, image, word });

            flagSize = flagS;
            flagType = flagT;

            if (flagSize || flagType) {
                return [];
            } else {
                files[i] = file;
            }
        }

        if (!flagSize && !flagType) {
            return files;
        }
    } else {
        const {
            file,
            flagSize: flagS,
            flagType: flagT
        }: any = await typesFile({ file: files, flagSize, flagType, image, word });

        flagSize = flagS;
        flagType = flagT;

        if (flagSize || flagType) {
            return undefined;
        } else {
            files = file;
        }

        if (!flagSize && !flagType) {
            return files;
        }
    }
};

export const maxSize = 15000000;
export const typesImage = ["jpeg", "jpg", "png", "webp", "svg"];
export const typesDocs = ["xls", "pdf", "xlsx", "docx", "doc", "docs"];

interface TypesFileParams {
    file: File;       // Окремий файл
    flagSize: boolean;
    flagType: boolean;
    image: boolean;
    word: () => string; // Функція, що повертає рядок
}

const typesFile = async ({ file, flagSize, flagType, image, word }: TypesFileParams) => {
    const types: any = [...typesImage, ...typesDocs];
    const options = {
        maxSizeMB: 0.5,
        // maxWidthOrHeight: 1900,
        useWebWorker: true,
        fileType: 'image/webp'
    };

    // const text = (code: number) => word(`error_text_${code}`);

    try {
        if (file instanceof Blob) {
            let fileCompress = file;

            const type: any = file?.name?.slice(-5).split(".")?.at(-1)?.toLowerCase();

            if (image && !typesImage.includes(type)) {

                return { file: null, flagSize: true, flagType: true };
            }

            if (!types.some((typ: any) => typ === type)) {

                return { flagSize, flagType: true };
            }

            if (fileCompress.size >= maxSize) {

                return { flagSize: true, flagType };
            }

            if (typesImage.some((typ) => typ === type)) {
                fileCompress = await imageCompression(file, options);
            }

            return { file: fileCompress, flagSize, flagType };
        } else {
            return { file: null, flagSize: true, flagType: true };
        }
    } catch (e) {
        console.log("error")
    }
};
